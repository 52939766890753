.main-body{
    background-color: #f9f9f9;
    margin-top: 12vh;
    min-height: 100vh;
    margin-left: 200px;
    padding-left: 60px;
    padding-right: 25px;
}

@media only screen and (max-width: 600px){
    .main-body{
        margin-left: 0px;
        margin-top: 9vh;
        padding: 10px;
    }
}

#b_ul li{
    width: 90px;
    text-align: center;
    border: solid thin #891AFF;
    border-bottom: solid thick #891AFF;
    margin-right: 10px;
    color: black;
}

.initialisation-container{
    background-color: #81D4FA;
    display: flex;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
}

