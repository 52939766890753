.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.bglight{
  background-color: #f2f2f2;
  padding: 20px;
}

.red-alert{
  color: red;
}


.table-form>tbody>tr{
  height: 80px;
}

.table-form>tbody>tr:nth-child(odd){
  background-color: #f9f9f9
}

.table-form>tbody>tr>td{
  padding: 10px;
}

*{
  font-family: 'Roboto', sans-serif;
}


a{
  color: inherit ;
  text-decoration: none
}

/* Form styling like bootstrap */

.form-control{
  padding: 10px;
  border: solid thin lightgrey;
  border-radius: 2px;
  width: 100%;
}

.form-control[type=date]{
  padding: 8px;
  border: solid thin lightgrey;
  border-radius: 2px;
  width: 100%;
}

.form-control::placeholder{
  color: grey;
  opacity: 1;
}

/* Table styling */


.table{
  width: 100%;
  border-collapse: collapse;
  
}

.table thead {
  background-color: lightsalmon;
}


.table thead tr td{
  padding: 3px;
  font-size: 0.8em;
  font-weight: 500;
  color: white;
  border: solid thin lightgrey;
}

.table tbody {
  background-color: white;
}

.table tbody tr td{
  padding: 3px;
  font-size: 0.8em;
  border: solid thin lightgrey;
}


.table tfoot {
  
}

.table tfoot tr td{
  padding: 3px;
  font-size: 0.9em;
  font-weight: 500;
  border: solid thin lightgrey;
}